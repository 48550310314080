.widget h4 a {
  color: #37474f;
  text-transform: uppercase;
  font-weight: bold;
  font-size:14px;
}
.gridster ul{
  list-style-type: none;
  margin:0;
  padding:0;

  li{
    list-style: none;
  }
}

.gridster-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.widget{
text-align:left;
 font-size: 14px;

  .table{
    font-size: 14px;
  }

}

.table-slidepanel-small {
  font-size: 11px !important;
}