.col-md-2_5{
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-md-2_5 {
    width: 20%;
    float: left;
  }
}

.widget-avail{
  width: 100px;
  //float:left;
  margin: 5px;


}
.widget-side{
  border:2px solid @color-blue-600;
  //color: #fff;
  height: 70px;
  font-size: 12px;
  line-height:100%;
  border-radius:10px;
  font-weight: 500;
  i{
    color: @color-blue-600;
    margin-bottom:10px;
  }

  .btn-icon i{
    color: #fff !important;
  }
}

.generic-widget{
  border:2px solid @color-blue-600;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  //color: #fff;

  margin:30px 0;
  border-radius: 10px;

}

.inner-widget-title{
  text-align: center;
  display: block;
  margin-bottom:10px;
  font-weight: bold;
  font-size: 14px;
}

.sortable-container-drop{
//  background: fade(@color-blue-500,5%);
  background-image: linear-gradient(45deg, #ffffff 25%, #fcfcfc 25%, #fcfcfc 50%, #ffffff 50%, #ffffff 75%, #fcfcfc 75%, #fcfcfc 100%);
  background-size: 28.28px 28.28px;
}

.complex-widget, .purple-widget{
 border:2px solid @color-purple-600;
  i{
    color: @color-purple-600;
  }
}


.red-widget{
  border:2px solid @color-red-600;
  i{
    color: @color-red-600;
  }
}

.component-widget, .green-widget{
 border:2px solid @color-green-600;
  i{
    color: @color-green-600;
  }
}

.layout-widget, .orange-widget{
 border:2px solid @color-orange-600;
  i{
    color: @color-orange-600;
  }
}

.sortable-container-drop{
  padding-top:30px;
  min-height:200px;
  border: 2px dotted #999;
}

.inner-form{
  text-align: left;
  font-size: 14px;
  width: 90%;
  margin: 0 auto;
}

.dz-default.dz-message{
  color: #333;
}

.widget-inner{
  margin:20px 0;
}

#builder-widgets-nav{
  margin-left:0 !important;
  margin-right:0 !important;
  .widget-inner{
    margin:0;
  }
}

#builder-widgets-nav{
 // background: #fff;
}

#builder-widgets-nav-sticky-wrapper{
  z-index:1010;

}

.inner-widgets{
  background: #fff;
}

.ui-sortable-placeholder{
  background: red;
}


.editable.mce-content-body{
  border:1px dotted #333;
}