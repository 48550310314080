.btn-visualedit{
 // color: @color-purple-800 !important;
}

.btn-visualedit.active{
  color: #fff !important;
  background: @color-purple-600 !important;
  &:hover{
    color: #fff !important;
    background: @color-purple-800 !important;
  }
}

.btn-colored(@color1, @color2){
  background-color:@color1;
  border-color: @color1;
  color: #fff;
  &.active{
    background-color: @color2;
    border-color: @color2;

  }

  &.btn-outline{
    color: @color1;
    background-color: transparent;

    &.active{
      color: #fff;
      background-color: @color1;
      border-color: @color1;

    }
  }
}



.btn-green{
  .btn-colored(@color-green-600, @color-green-800);
}

.btn-red{
  .btn-colored(@color-red-600, @color-red-800);
}

.btn-pink{
  .btn-colored(@color-pink-600, @color-pink-800);
}

.btn-purple{
  .btn-colored(@color-purple-600, @color-purple-800);
}

.btn-indigo{
  .btn-colored(@color-indigo-600, @color-indigo-800);
}

.btn-blue{
  .btn-colored(@color-blue-600, @color-blue-800);
}

.btn-cyan{
  .btn-colored(@color-cyan-600, @color-cyan-800);
}

.btn-teal{
  .btn-colored(@color-teal-600, @color-teal-800);
}

.btn-yellow{
  .btn-colored(@color-yellow-600, @color-yellow-800);
}

.btn-orange{
  .btn-colored(@color-orange-600, @color-orange-800);
}

.btn-brown{
  .btn-colored(@color-brown-600, @color-brown-800);
}

.btn-grey{
  .btn-colored(@color-blue-grey-600, @color-blue-grey-800);
}

.head-float{
  position: absolute;
  bottom: -26px;
  right: 20px;
}